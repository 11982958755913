import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'

const FallBackLoader = ({ anim }) => (
  <div
    style={{ width: '200px', height: '200px', margin: '0 auto' }}
    className="align-center"
  >
    <Loader
      type={anim || 'Circles'}
      color="#0073FF"
      className="loader"
      height={100}
      width={100}
    />
  </div>
)

FallBackLoader.propTypes = {
  anim: PropTypes.string,
}

export default FallBackLoader
