import React, { Suspense, lazy } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import FallBackLoader from 'ComponentsAndHooks/small-components/fallback-loader'
const Homepage = lazy(() => import('WebSite/pages/homepage'))
const NotFoundPage = lazy(() => import('WebSite/pages/not-found'))
const Copyright = lazy(() => import('WebSite/pages/copyright'))
const ResetPassword = lazy(() => import('WebSite/pages/reset-password'))
const EmailVerified = lazy(() => import('WebSite/pages/email-verified'))
const TermsPrivacy = lazy(() => import('WebSite/pages/terms-privacy'))
const MedyearAppGuideBook = lazy(() => import('WebSite/pages/medyear-app-guidebook'))
const MedyearProGuideBook = lazy(() => import('WebSite/pages/medyear-pro-guidebook'))
const MedyViewGuideBook = lazy(() => import('WebSite/pages/medyview-guidebook'))
const MedyPassGuideBook = lazy(() => import('WebSite/pages/medypass-guidebook'))
const Admin = lazy(() => import('WebSite/pages/admin/index'))
const LeadershipTeam = lazy(() => import('WebSite/pages/leadership-team'))
const DataView = lazy(() => import('WebSite/pages/data-view'))

// import Copyright from 'WebSite/pages/copyright'
// import DataView from 'WebSite/pages/data-view'
// import LeadershipTeam from 'WebSite/pages/leadership-team'
// import Admin from 'WebSite/pages/admin/index'
// import MedyPassGuideBook from 'WebSite/pages/medypass-guidebook'
// import MedyViewGuideBook from 'WebSite/pages/medyview-guidebook'
// import MedyearProGuideBook from 'WebSite/pages/medyear-pro-guidebook'
// import MedyearAppGuideBook from 'WebSite/pages/medyear-app-guidebook'
// import TermsPrivacy from 'WebSite/pages/terms-privacy'
// import EmailVerified from 'WebSite/pages/email-verified'
// import ResetPassword from 'WebSite/pages/reset-password'
// import Copyright from 'WebSite/pages/copyright'
// import NotFoundPage from 'WebSite/pages/not-found'
// import Homepage from 'WebSite/pages/homepage'

// import Store from 'WebSite/pages/store'
// import Covid19 from "WebSite/pages/covid-19"
// import Covid19Mobile from "WebSite/pages/covid-19/mobile"
// const Faq = lazy(() => import('WebSite/pages/faq'))

const history = createBrowserHistory()

function Routes() {
  history.listen((location) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
    // here general code for bot .. next lines are repeating
    const element = document.getElementById('health-bot-medyear')
    if (element) {
      // eslint-disable-next-line
      location.pathname !== '/'
        ? element.classList.add('hide')
        : element.classList.remove('hide')
    }
  })

  return (
    <Router history={history}>
      <Suspense fallback={<FallBackLoader />}>
        <Switch>
          {/* website routes */}
          <Route path="/" exact component={Homepage} />
          <Route path="/terms-and-conditions" exact component={TermsPrivacy} />
          {/* <Route path="/faq" exact component={Faq}/> */}
          <Route
            path="/privacy-policy"
            exact
            render={(hist) => <TermsPrivacy privacyPage history={hist} />}
          />
          {/* <Route path="/covid-19" exact component={Covid19} />
          <Route path="/covid-19-mobile" exact component={Covid19Mobile} /> */}
          <Route path="/email_verified.html" exact component={EmailVerified} />
          <Route path="/copyright" exact component={Copyright} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/choose-password" exact render={() => (
            <ResetPassword choose history={history} />
          )}/>
          <Route path="/admin" exact component={Admin} />
          <Route path="/medypass" exact component={MedyPassGuideBook} />
          <Route path="/medyview" exact component={MedyViewGuideBook} />
          <Route path="/medyear-pro" exact component={MedyearProGuideBook} />
          <Route path="/medyear-app" exact component={MedyearAppGuideBook} />
          <Route
            path="/data-view/:qrCode"
            render={(hist) => <DataView history={hist} />}
          />
          <Route path="/leadership-team" exact component={LeadershipTeam} />
          {/* <Route path="/store" exact component={Store} /> */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
