/* eslint-disable spaced-comment */
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useWindowSize } from 'ComponentsAndHooks/hooks/window-size'
import { wHeight } from 'ComponentsAndHooks/small-components/window-height'
import { LiveChatLoaderProvider } from 'react-live-chat-loader'
import Routes from 'routes'
import MedyearIcon from 'WebSite/general-assets/favicon.svg'

ReactGA.initialize('UA-161290573-1')

// meta constants
const siteUrl = `https://www.medyear.com`
const siteName = 'Medyear'
const siteDesc =
  'Medyear is a healthcare technology company that brings health records and people together in grounbreaking new ways.'
const twitter = 'https://twitter.com/medyears'

// json Ld Scripts Seo
const orgScript = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Medyear',
  award: '',
  url: 'https://www.medyear.com',
  logo: '',
  sameAs: [
    'https://www.facebook.com/medyear',
    'https://twitter.com/medyears',
    'https://www.linkedin.com/company/personiform-inc/',
    'https://www.instagram.com/medyear/',
  ],
}

const localBusinessScript = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Medyear',
  image: '',
  url: 'https://www.medyear.com',
  telephone: '+1-929-777-4929',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '447 Broadway, Second Floor, New York, NY 10013',
    addressLocality: 'New York',
    postalCode: '10013',
    addressCountry: 'United States',
    addressRegion: 'New York',
  },
  openingHours: ['Mo-Fr 10:00-19:00'],
  priceRange: '$',
}

const webSiteScript = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Medyear',
  url: 'https://www.medyear.com/',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.medyear.com/search/{search_term_string}',
    'query-input': 'required name=search_term_string',
  },
}

const siteNavScript = {
  '@context': 'https://schema.org',
  '@type': 'SiteNavigationElement',
  name: [
    'Home',
    'Terms and Conditions',
    'Privacy Policy',
    'FAQ',
    'Copyright',
    'About',
  ],
  url: [
    'https://www.medyear.com',
    'https://www.medyear.com/terms-and-conditions',
    'https://www.medyear.com/privacy-policy',
    'https://www.medyear.com/faq',
    'https://www.medyear.com/copyright',
    'https://www.medyear.com/about',
  ],
}

const renderMetaData = () => (
  <Helmet>
    <link rel="canonical" href={`${siteUrl}`} />
    <meta property="og:url" content={siteUrl} />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_us" />
    <meta property="og:site_name" content={siteName} />
    <meta property="og:description" content={siteDesc} />
    <meta property="og:image" content={`${siteUrl}${MedyearIcon}`} />
    <meta property="og:image:width" content="512" />
    <meta property="og:image:height" content="512" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content={twitter} />
  </Helmet>
)

ReactGA.pageview(window.location.pathname + window.location.search)

const renderJsonLdScripts = () => (
  <Helmet>
    <script type="application/ld+json">{JSON.stringify(orgScript)}</script>
    <script type="application/ld+json">
      {JSON.stringify(localBusinessScript)}
    </script>
    <script type="application/ld+json">{JSON.stringify(webSiteScript)}</script>
    <script type="application/ld+json">{JSON.stringify(siteNavScript)}</script>
    <script src="https://t.cdc.gov/1M1B" />
  </Helmet>
)

/**
 * Routing
 * @returns {*}
 * @constructor
 */
function App() {
  const [bottom, setBottom] = useState(false)
  let chatBotClass = 'cdc-widget-color-white cdc-chat-bot'
  const mobile = useWindowSize().width < 1024
  const isHomePage = window.location.pathname === '/'

  useScrollPosition(() => {
    wHeight(setBottom)
  })

  if (mobile && bottom) {
    chatBotClass += ' hide'
  }

  return (
    <LiveChatLoaderProvider
      providerKey="28efb073-de39-4a77-b053-f8ad11d034cb"
      provider="helpScout"
    >
      {renderMetaData()}
      {renderJsonLdScripts()}
      <Routes />
      {isHomePage && (
        <div
          data-cdc-widget="healthBot"
          data-cdc-theme="theme1"
          className={chatBotClass}
          data-cdc-language="en-us"
          id="health-bot-medyear"
        />
      )}
    </LiveChatLoaderProvider>
  )
}

export default App
